import type { GetRouteFunc } from "@hippocrates-holding/gluon"
import { customPaths } from "~/utils/constants"

const getTypedRoutes = <T extends TCmsRoutes>(cr: T): Readonly<T> => cr

export const cmsRoutes = getTypedRoutes({
  GenericPage: (slug: string) => `${customPaths.genericPage}/${slug}`,
  ProductCategoryPage: (slug: string) => `/c-${slug}`
})

export const getRouteByTypename = <T extends keyof typeof cmsRoutes>(
  typename: T
): typeof cmsRoutes[T] => cmsRoutes[typename]

export const getRoute: GetRouteFunc<typeof cmsRoutes> = (...params: any) => {
  const [routeType, routeParams] = params
  if (routeParams) {
    // @ts-ignore
    return typeof cmsRoutes[routeType] === "function"
      ? // @ts-ignore
        cmsRoutes[routeType](...routeParams)
      : // @ts-ignore
        cmsRoutes[routeType]
  }
  // @ts-ignore
  return cmsRoutes[routeType]
}
