<script lang="ts" setup>
const { locale } = useI18n()

definePageMeta({
  pageType: "Homepage"
})

const { data, error } = await usePage(
  `/api/gluon/fetchHomePage/${locale.value}/home.js`
)

if (error.value) {
  throw createError({
    message: error.value?.message,
    statusCode: 404
  })
}
</script>

<template>
  <div class="homepage">
    <SeoBase v-bind="data!.seo" />
    <PagesHomepage :page-data="data!" />
  </div>
</template>
