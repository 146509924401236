<script setup lang="ts">
import type { WrapperImageProps } from "./WrapperImage.props"
import { useFocalPoint } from "~/composables/useFocalPoint"

const props = defineProps<WrapperImageProps>()
const setFocalPoint: Ref<string | Record<string, string>> = ref("")

const { width } = useWindowSize()

watch(
  width,
  () => {
    // In order to active the contentful's focal point, you gotta use these props: "activateFocalPoint", "focalPoint", "image.width" and "image.height"
    if (props.activateFocalPoint && props.focalPoint) {
      const { focalPoint } = useFocalPoint(
        props.focalPoint?.x || 200,
        props.focalPoint?.y || 200,
        props.image.width,
        props.image.height
      )
      setFocalPoint.value = focalPoint.value
    }
  },
  { immediate: true }
)

const isSVG = computed(() => !!props.image?.url?.includes(".svg"))
const isImageWithAttributeProrityHigh = computed(() => {
  return (
    props.imgAttrs &&
    // @ts-ignore
    props.imgAttrs["fetchpriority"] &&
    // @ts-ignore
    props.imgAttrs["fetchpriority"] === "high"
  )
})

const imgAttrs = computed(() => {
  return {
    style: `${props.deleteWidthFullFromStyle ? "" : "width: 100%;"}${
      setFocalPoint.value
        ? "object-position:" + setFocalPoint.value?.["object-position"]
        : ""
    }`,
    ...props.imgAttrs
  }
})

// As per TF-2700, sometimes the image is not available because of the image's size we try to fetch from Contentful .. because if contentful doesn't support it -> it throws an error -> hence making the image not visible .. so in that case we just re-fetch the image with no defined size (so its dynamic) whenever that happens
const imageInError = ref(false)
const handleError = () => (imageInError.value = true)
</script>

<template>
  <div class="wrapper-image">
    <NuxtImg
      v-if="isSVG"
      :src="image?.url"
      class="image-svg h-full w-full"
      :fit="fit"
      :height="image.height"
      :width="image.width"
      :alt="altText"
      :title="titleText"
      :sizes="sizes"
      provider="contentful"
      placeholder
      loading="lazy"
    />
    <NuxtPicture
      v-else
      format="webp"
      provider="contentful"
      :src="image?.url"
      :height="imageInError ? undefined : image.height"
      :width="imageInError ? undefined : image.width"
      :alt="altText"
      :title="titleText"
      :sizes="sizes"
      :img-attrs="imgAttrs"
      placeholder
      :preload="preload"
      :loading="!isImageWithAttributeProrityHigh ? 'lazy' : undefined"
      v-on:error="handleError"
    />
  </div>
</template>
