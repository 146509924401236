import { t } from "./i18n"

const weekdays = [
  {
    id: "MON",
    label: t("shortWeekdays.monday")
  },
  {
    id: "TUE",
    label: t("shortWeekdays.tuesday")
  },
  {
    id: "WED",
    label: t("shortWeekdays.wednesday")
  },
  {
    id: "THU",
    label: t("shortWeekdays.thursday")
  },
  {
    id: "FRI",
    label: t("shortWeekdays.friday")
  },
  {
    id: "SAT",
    label: t("shortWeekdays.saturday")
  },
  {
    id: "SUN",
    label: t("shortWeekdays.sunday")
  }
]

export const getCompactTimetable: (
  openingDays: {
    hours: {
      openingTime: string
      closingTime: string
    }[]
    validFrom?: string
    weekday: string
  }[]
) => {
  weekdayFrom: string
  weekdayTo?: string
  hours: {
    openingTime: string
    closingTime: string
  }[]
}[] = (
  openingDays: {
    hours: {
      openingTime: string
      closingTime: string
    }[]
    validFrom?: string
    weekday: string
  }[]
) => {
  const compactTimeTable: {
    weekdayFrom: string
    weekdayTo?: string
    hours: {
      openingTime: string
      closingTime: string
    }[]
  }[] = []

  let currentItem:
    | {
        weekdayFrom: string
        weekdayTo?: string
        hours: {
          openingTime: string
          closingTime: string
        }[]
      }
    | undefined

  for (let weekday of weekdays) {
    let weekdayOpening = openingDays.find(
      (openingDay) => openingDay.weekday == weekday.id
    )
    if (!weekdayOpening) {
      weekdayOpening = {
        hours: [],
        weekday: weekday.id
      }
    }

    if (!currentItem) {
      currentItem = {
        weekdayFrom: weekday.label,
        hours: weekdayOpening.hours
      }
    } else {
      // Check if the two hours array contain the same times
      if (
        currentItem.hours.every(
          (hour) =>
            !!weekdayOpening.hours.find(
              (otherHour) =>
                otherHour.openingTime == hour.openingTime &&
                otherHour.closingTime == hour.closingTime
            )
        ) &&
        weekdayOpening.hours.every(
          (hour) =>
            !!currentItem!.hours.find(
              (otherHour) =>
                otherHour.openingTime == hour.openingTime &&
                otherHour.closingTime == hour.closingTime
            )
        )
      ) {
        // Add the weekday as "weekday to" and continue
        currentItem.weekdayTo = weekday.label
      } else {
        // Push the current item to the result list, and initialize the next iteration item
        compactTimeTable.push(currentItem)
        currentItem = {
          weekdayFrom: weekday.label,
          hours: weekdayOpening.hours
        }
      }
    }
  }

  if (!!currentItem) {
    compactTimeTable.push(currentItem)
  }

  return compactTimeTable
}
