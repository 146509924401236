import type { SkuListItem, SkuListItemCreate } from "@commercelayer/sdk"
import type { LineItemMetadata } from "./useCart"
import { storeToRefs } from "pinia"
import { useUserStore } from "~/stores/user"
import type { NotificationAlertProps as Notification } from "~/components/NotificationAlert.props"
import { formatPrice } from "#imports"
import { checkEnvSite } from "~/server/utils/getAlgoliaConfig"
import {
  getSkuListItemCreateObject,
  anonymousSaveForLaterProductStorageKey
} from "~/utils/savedForLater/savedForLater"
import { navigateToLoginSignupPage } from "~/utils/loginSignup/loginSignup"
import { GA4Entities } from "nuxt-ga4"

export const REFERENCE = "saved_for_later"

export type _SkuListItemCreate = Omit<SkuListItemCreate, "sku_list"> & {
  metadata: LineItemMetadata & { isOutOfStock?: boolean }
}

export interface SaveForLaterProductForAction {
  skuId: string
  productId: string
  metadata: LineItemMetadata
}

export default () => {
  const {
    addToCart,
    decreaseQuantity,
    lineItems,
    freeGiftPromotions,
    soldOutCompareData,
    removeFromSoldOutCompareData
  } = useCart()

  const userStore = useUserStore()
  const { userId } = storeToRefs(userStore)
  const isLogged = useIsLogged()
  const { addNotification, removeNotification } = useNotification()
  const { t } = useI18n()
  const anonymousSaveForLaterProductStorage = ref("")

  const { $cl } = useNuxtApp()

  // SAVED FOR LATER
  const savedForLater = useSkuList("savedForLater")

  const loadSavedForLaterId = async () => {
    if (!isLogged.value || !userId.value) return null

    const skusLists = await $cl.customers.sku_lists(userId.value, {
      fields: ["id", "reference"],
      filters: { reference_eq: REFERENCE }
    })

    return skusLists?.[0]?.id ?? null
  }

  // INIT
  const initSavedForLater = async () => {
    const id = await loadSavedForLaterId()

    savedForLater.initSkuList(id)

    await savedForLater.fetchSkuList()
  }

  // RESET
  const resetSavedForLater = () => {
    savedForLater.initSkuList(null)
  }

  // SAVED FOR LATER METHODS
  const refreshSavedForLater = async () => {
    await savedForLater.fetchSkuList()
  }

  const getCreateItemAndAddToSavedForLater = (
    skuCode: string,
    metadata: LineItemMetadata
  ) => {
    const prodForSaveForLater = getSkuListItemCreateObject(skuCode, metadata)
    addToSavedForLater(prodForSaveForLater)
  }

  const addToSavedForLater = async (
    attributes: _SkuListItemCreate,
    refresh = true
  ) => {
    if (!savedForLater.skuList.value) {
      await savedForLater.createSkuList({
        name: `${REFERENCE}-${userId.value}`,
        reference: REFERENCE
      })
    }

    if (!attributes.sku.id) {
      throw new Error("Sku id is undefined")
    }

    if (
      !savedForLater.skuListItems.value?.length ||
      !savedForLater.skuListItems.value.find(
        (item) => item?.sku?.id === attributes.sku.id
      )
    ) {
      const { sendEventSaveLater } = useGA4SiteEvents()
      if (attributes.metadata.ga4Item) {
        sendEventSaveLater(attributes.metadata.ga4Item, {
          currentPrice: parseInt(attributes?.metadata?.price)?.toFixed(2) ?? "",
          currency: "EUR"
        })
      }
      handleSavedForLaterNotification()
      return savedForLater.addSkuItem(attributes, refresh)
    }
  }

  const addToSavedForLaterBySKU = async (
    skuCode: string,
    metadata: LineItemMetadata
  ) => {
    const [sku] = await $cl.skus.list({
      include: [""],
      fields: {},
      filters: { code_eq: skuCode }
    })

    if (!sku) {
      return
    }

    getCreateItemAndAddToSavedForLater(sku.id, metadata)
  }

  const moveToSavedForLater = async (skuCode: string) => {
    const lineItem = lineItems.value.find((item) => item.sku_code === skuCode)!
    const freeGiftQuantity = getFreeGiftQuantity(freeGiftPromotions.value)(
      lineItem
    )
    const nonFreeGiftQuantity = lineItem.quantity! - freeGiftQuantity

    try {
      await addToSavedForLater({
        sku: {
          id: lineItem.item.id,
          type: "skus"
        },
        metadata: {
          ...(lineItem.metadata as LineItemMetadata)
        }
      })

      await decreaseQuantity(lineItem.id, nonFreeGiftQuantity)
    } catch (e) {
      console.error(e)
    }
  }

  const moveSoldOutToSavedForLater = async (skuCode: string) => {
    const lineItem = soldOutCompareData.value.find(
      (item) => item.sku_code === skuCode
    )!

    try {
      await addToSavedForLater({
        sku: {
          id: lineItem.item.id,
          type: "skus"
        },
        metadata: {
          ...(lineItem.metadata as LineItemMetadata),
          isOutOfStock: true
        }
      })

      removeFromSoldOutCompareData(lineItem.id)
    } catch (e) {
      console.log(e)
    }
  }

  const addSavedForLaterToCart = async (
    skuCode: string,
    ga4Item: GA4Entities["gaItem"],
    move: boolean = false
  ) => {
    const lineItem = savedForLater.skuListItems.value.find(
      (item) => item.sku_code === skuCode
    )!

    if (move) {
      removeFromSavedForLaterBySku(skuCode)
    }

    await addToCart({
      sku_code: skuCode,
      metadata: {
        ...(lineItem.metadata as LineItemMetadata),
        ga4Item: {
          ...(lineItem.metadata as LineItemMetadata).ga4Item,
          ...ga4Item
        }
      }
    })
  }

  const updateSavedForLaterBackInStock = async () => {
    for (const skuListItem of savedForLater.skuListItems.value) {
      await $cl.sku_list_items.update({
        id: skuListItem.id,
        metadata: {
          ...skuListItem.metadata,
          isOutOfStock: !skuListItem.sku?.inventory?.available
        }
      })
    }
  }

  const setAnonymousSaveForLaterProductStorage = (
    product: SaveForLaterProductForAction | string
  ) => {
    localStorage.setItem(
      anonymousSaveForLaterProductStorageKey,
      JSON.stringify(product)
    )
    anonymousSaveForLaterProductStorage.value =
      getAnonymousSaveForLaterProductStorage()
  }

  const getAnonymousSaveForLaterProductStorage = () => {
    const product = localStorage.getItem(anonymousSaveForLaterProductStorageKey)
    return product ? JSON.parse(product) : ""
  }

  const addToSavedForLaterByStorage = () => {}

  const handleSaveForLaterBySkuListOrSkuId = async (
    product: SaveForLaterProductForAction
  ) => {
    if (!product) {
      return
    }

    const { skuId, productId, metadata } = product

    if (skuId) {
      getCreateItemAndAddToSavedForLater(skuId, metadata)
      return
    }

    await addToSavedForLaterBySKU(productId, metadata)
  }

  const handleRemoveSavedForLaterBySku = async (
    product: SaveForLaterProductForAction
  ) => {
    if (!product) {
      return
    }

    const { productId } = product
    await removeFromSavedForLaterBySku(productId)
  }

  const handleSaveForLaterBySetOnStorage = (
    product: SaveForLaterProductForAction
  ) => {
    setAnonymousSaveForLaterProductStorage(product)
    navigateToLoginSignupPage()
  }

  const handleAutomaticSaveForLaterForAnonymous = async () => {
    const savedForLaterProductFromStorage =
      getAnonymousSaveForLaterProductStorage()
    await handleSaveForLaterBySkuListOrSkuId(savedForLaterProductFromStorage)
  }

  const getSortedProductCodeByCreatedAt = (lineItems: SkuListItem[]) => {
    return lineItems
      .sort(
        (a, b) =>
          new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
      )
      .map((item) => item.sku_code)
  }

  const getSortedItemsByCreatedAt = (lineItems: SkuListItem[]) => {
    return lineItems.sort(
      (a, b) =>
        new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
    )
  }

  const backInStockLineItems = computed(() =>
    savedForLater.skuListItems.value.filter(
      (skuListItem) =>
        skuListItem?.metadata?.isOutOfStock &&
        skuListItem.sku?.inventory?.available
    )
  )

  const notifyPriceVariation = async (onlyReductions: boolean = false) => {
    refreshSavedForLater().then(() => {
      const notifications = {
        lower: <any>[],
        higher: <any>[]
      }
      savedForLater.skuListItems.value.map((skuListItem, key) => {
        const oldPrice =
          skuListItem.metadata?.price ?? skuListItem.metadata?.comparePrice
        if (
          skuListItem.sku?.prices?.[0]?.amount_float &&
          oldPrice &&
          skuListItem.sku.prices[0].amount_float != oldPrice
        ) {
          const difference =
            skuListItem.metadata?.price > skuListItem.sku.prices[0].amount_float
              ? "lower"
              : "higher"
          if (!onlyReductions || difference == "lower") {
            savedForLater.skuListItems.value[key].metadata.price =
              skuListItem.sku.prices[0].amount_float
            notifications[difference].push(
              t(
                `organism.myAccount.savedForLater.notifications.variation.${difference}`,
                {
                  productName: "<strong>" + skuListItem.sku?.name + "</strong>",
                  oldPrice: formatPrice(oldPrice * 100),
                  price: formatPrice(
                    skuListItem.sku.prices[0].amount_float * 100
                  )
                }
              )
            )
            $cl.sku_list_items.update({
              id: skuListItem.id,
              metadata: {
                ...skuListItem.metadata,
                price: skuListItem.sku.prices[0].amount_float,
                comparePrice: skuListItem.sku.prices[0].amount_float
              }
            })
            savedForLater.fetchSkuList()
          }
        }
      })

      if (notifications.lower.length + notifications.higher.length) {
        const msg =
          notifications.lower.join("<br>") +
          (notifications.lower.length && notifications.higher.length
            ? "<br>"
            : "") +
          notifications.higher.join("<br>")
        const notificationMessage: Notification = {
          id: "SavedForLaterPriceChanged",
          type: "warning",
          notificationAlertTitle: t(
            "organism.myAccount.savedForLater.notifications.variation.title"
          ),
          notificationAlertText: msg,
          isWithRepresentativeIcon: true,
          isPersistent: true,
          isWithCloseIcon: true
        }
        removeNotification("SavedForLaterPriceChanged")
        addNotification(notificationMessage)
      }
    })
  }

  function getAlgoliaProductsCodesQuery() {
    return savedForLater.skuListItems.value
      ?.map((product) => "productCode:" + product.sku_code)
      .join(" OR ")
  }

  function getAlgoliaOutOfCommerceQuery() {
    const productCodes = getAlgoliaProductsCodesQuery()
    return (
      "(" +
      productCodes +
      ") AND (sellabilityStatus:not_sellable OR sellabilityStatus:out_of_market) AND inStock:false"
    )
  }

  const notifyOutOfCommerce = async () => {
    removeNotification("savedForLaterOutOfCommerce")
    if (savedForLater.skuListItems.value?.length) {
      const { search } = useAlgoliaSearch(`TOP_PRODUCTS${checkEnvSite}`)
      const query = getAlgoliaOutOfCommerceQuery()
      const { data: outOfCommerceProducts } = useAsyncData(
        "outOfCommerceProducts",
        () =>
          search({
            query: "",
            requestOptions: {
              filters: query,
              hitsPerPage: 100,
              clickAnalytics: true
            }
          }),
        {
          immediate: true,
          transform: (searchResponse) => mapProductsAlgolia(searchResponse.hits)
        }
      )
      if (outOfCommerceProducts.value?.length) {
        const msg =
          t(
            "organism.myAccount.savedForLater.notifications.outOfCommerce.subtitle"
          ) +
          `<ul class="pt-2 pl-4 list-disc"><li>` +
          outOfCommerceProducts.value
            .map((product: any) =>
              t(
                `organism.myAccount.savedForLater.notifications.outOfCommerce.product`,
                {
                  productName: "<strong>" + product.title + "</strong>"
                }
              )
            )
            .join("</li><li>") +
          "</li></ul>"

        const notificationMessage: Notification = {
          id: "savedForLaterOutOfCommerce",
          type: "danger",
          notificationAlertTitle: t(
            "organism.myAccount.savedForLater.notifications.outOfCommerce.title"
          ),
          notificationAlertText: msg,
          cta: t(
            "organism.myAccount.savedForLater.notifications.outOfCommerce.cta"
          ),
          ctaFunction: removeOutOfCommerce,
          isWithRepresentativeIcon: true,
          isPersistent: true,
          isWithCloseIcon: true
        }
        addNotification(notificationMessage)
      }
    }
  }

  const removeOutOfCommerce = async () => {
    removeNotification("savedForLaterOutOfCommerce")
    const query = getAlgoliaOutOfCommerceQuery()
    const { data: outOfCommerceProducts } = useAsyncData(
      "outOfCommerceProducts",
      () =>
        search({
          query: "",
          requestOptions: {
            filters: query,
            hitsPerPage: 100,
            clickAnalytics: true
          }
        }),
      {
        immediate: true,
        transform: (searchResponse) => mapProductsAlgolia(searchResponse.hits)
      }
    )
    outOfCommerceProducts.value.forEach((product) => {
      removeFromSavedForLaterBySku(product.productCode)
    })
  }

  const handleSavedForLaterNotification = () => {
    const { isOpen } = useUIState("focus-search")
    const notification: Notification = {
      id: "addToSavedForLater",
      type: "success",
      notificationAlertText: t("productTile.overall.savedForLater"),
      isWithCloseIcon: true,
      small: isOpen.value
    }
    removeNotification("addToSavedForLater")
    addNotification(notification)
  }

  const removeFromSavedForLater = savedForLater.removeSkuItem

  const removeFromSavedForLaterBySku = savedForLater.removeSkuItemBySku

  const isInSavedForLater = savedForLater.isInSkuList

  return {
    // SAVED FOR LATER
    savedForLaterId: savedForLater.skuListId,
    savedForLater: savedForLater.skuList,
    lineItems: savedForLater.skuListItems,
    savedForLaterLoading: savedForLater.loading,
    // SAVED FOR LATER METHODS
    initSavedForLater,
    refreshSavedForLater,
    resetSavedForLater,
    addSavedForLaterToCart,
    addToSavedForLater,
    addToSavedForLaterBySKU,
    getAlgoliaProductsCodesQuery,
    moveToSavedForLater,
    moveSoldOutToSavedForLater,
    notifyPriceVariation,
    notifyOutOfCommerce,
    // getLineItemAttributes,
    removeFromSavedForLater,
    removeFromSavedForLaterBySku,
    isInSavedForLater,
    updateSavedForLaterBackInStock,
    backInStockLineItems,
    getAnonymousSaveForLaterProductStorage,
    setAnonymousSaveForLaterProductStorage,
    addToSavedForLaterByStorage,
    handleSaveForLaterBySkuListOrSkuId,
    handleRemoveSavedForLaterBySku,
    handleSaveForLaterBySetOnStorage,
    anonymousSaveForLaterProductStorage,
    handleAutomaticSaveForLaterForAnonymous,
    getSortedProductCodeByCreatedAt,
    getSortedItemsByCreatedAt
  }
}
