import { createI18n } from "vue-i18n"
import type { I18nOptions } from "vue-i18n"

import it from "../../locales/it.json"

const messages: I18nOptions["messages"] = Object.assign({
  it: it
})

const i18n = createI18n({
  legacy: false,
  locale: "it",
  availableLocales: ["it"], // note: you declare two available locales but only have one in messages
  messages
})

export const { t, locale } = i18n.global
