import { default as indexBAsN6gqCRIMeta } from "/opt/build/repo/pages/[generic]/index.vue?macro=true";
import { default as _91page_93SSlNfEQJIQMeta } from "/opt/build/repo/pages/[generic]/page/[page].vue?macro=true";
import { default as indexjFT4QMHugjMeta } from "/opt/build/repo/pages/accedi-registrati/crea-account/index.vue?macro=true";
import { default as indexR94K3mwxfrMeta } from "/opt/build/repo/pages/accedi-registrati/index.vue?macro=true";
import { default as indexuSjNz7fG7rMeta } from "/opt/build/repo/pages/accedi-registrati/login/index.vue?macro=true";
import { default as indexKG4T2TGxZSMeta } from "/opt/build/repo/pages/area-personale/acquisti-resi/index.vue?macro=true";
import { default as _91orderID_93tWi3zeYGTzMeta } from "/opt/build/repo/pages/area-personale/acquisti-resi/ordine/[orderID].vue?macro=true";
import { default as _91orderID_93QNqZvgalG2Meta } from "/opt/build/repo/pages/area-personale/acquisti-resi/reso/[orderID].vue?macro=true";
import { default as buoniEm5i4ZAQJPMeta } from "/opt/build/repo/pages/area-personale/buoni.vue?macro=true";
import { default as datilorYguu5xuMeta } from "/opt/build/repo/pages/area-personale/dati.vue?macro=true";
import { default as indexT7nUtMi3DNMeta } from "/opt/build/repo/pages/area-personale/index.vue?macro=true";
import { default as _91editID_93PETb0CYANDMeta } from "/opt/build/repo/pages/area-personale/indirizzi/[editID].vue?macro=true";
import { default as indexICbVUygLkUMeta } from "/opt/build/repo/pages/area-personale/indirizzi/index.vue?macro=true";
import { default as nuovo5kLED19RAJMeta } from "/opt/build/repo/pages/area-personale/indirizzi/nuovo.vue?macro=true";
import { default as indexPlEoG8vsojMeta } from "/opt/build/repo/pages/area-personale/preferiti/index.vue?macro=true";
import { default as _91page_933MyoTbKLWRMeta } from "/opt/build/repo/pages/area-personale/preferiti/page/[page].vue?macro=true";
import { default as prodotti_45disponibiliGIjRsz432DMeta } from "/opt/build/repo/pages/area-personale/prodotti-disponibili.vue?macro=true";
import { default as wishlisth8PSw8QrQaMeta } from "/opt/build/repo/pages/area-personale/wishlist.vue?macro=true";
import { default as indexCwEQMvbj7rMeta } from "/opt/build/repo/pages/b-[brand]/[slug]/index.vue?macro=true";
import { default as _91page_93qBOJlOKez5Meta } from "/opt/build/repo/pages/b-[brand]/[slug]/page/[page].vue?macro=true";
import { default as indexaUhMcyycnVMeta } from "/opt/build/repo/pages/b-[brand]/index.vue?macro=true";
import { default as _91page_93oFvd8nLWGkMeta } from "/opt/build/repo/pages/b-[brand]/page/[page].vue?macro=true";
import { default as brands0PHvUwVTdiMeta } from "/opt/build/repo/pages/brands.vue?macro=true";
import { default as indexNGTo1wtfXiMeta } from "/opt/build/repo/pages/c-[category]/index.vue?macro=true";
import { default as _91page_93bXyh0KsniOMeta } from "/opt/build/repo/pages/c-[category]/page/[page].vue?macro=true";
import { default as carrellofUc11WNL3BMeta } from "/opt/build/repo/pages/carrello.vue?macro=true";
import { default as _91id_938KYaDbpSESMeta } from "/opt/build/repo/pages/checkout/[id].vue?macro=true";
import { default as indexz5dTd4TBP7Meta } from "/opt/build/repo/pages/checkout/index.vue?macro=true";
import { default as chi_45siamoBz2YjIN22oMeta } from "/opt/build/repo/pages/chi-siamo.vue?macro=true";
import { default as contattiuzIMrJV0pWMeta } from "/opt/build/repo/pages/contatti.vue?macro=true";
import { default as domande_45frequentiq5qF18HL7EMeta } from "/opt/build/repo/pages/domande-frequenti.vue?macro=true";
import { default as _91article_93h6xOIDfs0KMeta } from "/opt/build/repo/pages/enciclopedia/[category]/[article].vue?macro=true";
import { default as indexXngl67Q7hqMeta } from "/opt/build/repo/pages/enciclopedia/[category]/index.vue?macro=true";
import { default as _91page_93J1IzCMxdpEMeta } from "/opt/build/repo/pages/enciclopedia/[category]/page/[page].vue?macro=true";
import { default as indexLQBRbWCa2ZMeta } from "/opt/build/repo/pages/enciclopedia/index.vue?macro=true";
import { default as health_45checkBxn3ItgCqQMeta } from "/opt/build/repo/pages/health-check.vue?macro=true";
import { default as indexlaFeZuYZkUMeta } from "/opt/build/repo/pages/index.vue?macro=true";
import { default as loginBXD9sq3T3sMeta } from "/opt/build/repo/pages/login.vue?macro=true";
import { default as _91refOrder_93bIPfyRftwdMeta } from "/opt/build/repo/pages/ordine/traccia/[email]/[refOrder].vue?macro=true";
import { default as trovaWxQk7ASVerMeta } from "/opt/build/repo/pages/ordine/trova.vue?macro=true";
import { default as p_45_91product_93_46constU2OD4q9Sv4Meta } from "/opt/build/repo/pages/p-[product].const.ts?macro=true";
import { default as p_45_91product_93E7gXPSwJQgMeta } from "/opt/build/repo/pages/p-[product].vue?macro=true";
import { default as indexQiExrVPiPcMeta } from "/opt/build/repo/pages/password-dimenticata/index.vue?macro=true";
import { default as modifica_45passwordGoJJyz8DQYMeta } from "/opt/build/repo/pages/password-dimenticata/modifica-password.vue?macro=true";
import { default as indexjrKiqFWcmmMeta } from "/opt/build/repo/pages/piu-acquistati/index.vue?macro=true";
import { default as _91page_93lHTT0MM2xdMeta } from "/opt/build/repo/pages/piu-acquistati/page/[page].vue?macro=true";
import { default as profilo_45cancellato2DiM9hmnlKMeta } from "/opt/build/repo/pages/profilo-cancellato.vue?macro=true";
import { default as index6pxx5XbGeeMeta } from "/opt/build/repo/pages/promozioni/brand/[slug]/index.vue?macro=true";
import { default as _91page_93hXuvABQhtlMeta } from "/opt/build/repo/pages/promozioni/brand/[slug]/page/[page].vue?macro=true";
import { default as indexHFWpM3cPk4Meta } from "/opt/build/repo/pages/promozioni/index.vue?macro=true";
import { default as indexY3fHhsOGd5Meta } from "/opt/build/repo/pages/promozioni/interne/[slug]/index.vue?macro=true";
import { default as _91page_93w0wI6gtigBMeta } from "/opt/build/repo/pages/promozioni/interne/[slug]/page/[page].vue?macro=true";
import { default as _91slug_93W18cDwZAlBMeta } from "/opt/build/repo/pages/promozioni/special/[slug].vue?macro=true";
import { default as indexaC1HOsJopwMeta } from "/opt/build/repo/pages/ricerca/index.vue?macro=true";
import { default as _91page_93q6Pm6jsPkeMeta } from "/opt/build/repo/pages/ricerca/page/[page].vue?macro=true";
import { default as _91file_93MAh3jESMNoMeta } from "/opt/build/repo/pages/ricevuta/[file].vue?macro=true";
import { default as richiedi_45prodotto7uFecg0PPwMeta } from "/opt/build/repo/pages/richiedi-prodotto.vue?macro=true";
import { default as scontiqXktzTDAFOMeta } from "/opt/build/repo/pages/sconti.vue?macro=true";
import { default as sitemapx1fjhjGxmTMeta } from "/opt/build/repo/pages/sitemap.vue?macro=true";
import { default as social_45loginPWYrRfhs2eMeta } from "/opt/build/repo/pages/social-login.vue?macro=true";
import { default as indexHn1P26pVy5Meta } from "/opt/build/repo/pages/top-magazine/[category]/[slug]/index.vue?macro=true";
import { default as indexa2OoLPajMOMeta } from "/opt/build/repo/pages/top-magazine/[category]/index.vue?macro=true";
import { default as _91page_93hefhzrrG0vMeta } from "/opt/build/repo/pages/top-magazine/[category]/page/[page].vue?macro=true";
import { default as indexgz58Wm79gIMeta } from "/opt/build/repo/pages/top-magazine/index.vue?macro=true";
import { default as indexGAXrrwPzOqMeta } from "/opt/build/repo/pages/top-magazine/tutti-i-post/index.vue?macro=true";
import { default as _91page_93G46ZqFrjStMeta } from "/opt/build/repo/pages/top-magazine/tutti-i-post/page/[page].vue?macro=true";
import { default as component_45stubVlEI75ehPBMeta } from "/opt/build/repo/node_modules/nuxt/dist/pages/runtime/component-stub?macro=true";
export default [
  {
    name: indexBAsN6gqCRIMeta?.name ?? "generic",
    path: indexBAsN6gqCRIMeta?.path ?? "/:generic()",
    meta: indexBAsN6gqCRIMeta || {},
    alias: indexBAsN6gqCRIMeta?.alias || [],
    redirect: indexBAsN6gqCRIMeta?.redirect,
    component: () => import("/opt/build/repo/pages/[generic]/index.vue").then(m => m.default || m)
  },
  {
    name: _91page_93SSlNfEQJIQMeta?.name ?? "generic-page-page",
    path: _91page_93SSlNfEQJIQMeta?.path ?? "/:generic()/page/:page()",
    meta: _91page_93SSlNfEQJIQMeta || {},
    alias: _91page_93SSlNfEQJIQMeta?.alias || [],
    redirect: _91page_93SSlNfEQJIQMeta?.redirect,
    component: () => import("/opt/build/repo/pages/[generic]/page/[page].vue").then(m => m.default || m)
  },
  {
    name: indexjFT4QMHugjMeta?.name ?? "accedi-registrati-crea-account",
    path: indexjFT4QMHugjMeta?.path ?? "/accedi-registrati/crea-account",
    meta: indexjFT4QMHugjMeta || {},
    alias: indexjFT4QMHugjMeta?.alias || [],
    redirect: indexjFT4QMHugjMeta?.redirect,
    component: () => import("/opt/build/repo/pages/accedi-registrati/crea-account/index.vue").then(m => m.default || m)
  },
  {
    name: indexR94K3mwxfrMeta?.name ?? "accedi-registrati",
    path: indexR94K3mwxfrMeta?.path ?? "/accedi-registrati",
    meta: indexR94K3mwxfrMeta || {},
    alias: indexR94K3mwxfrMeta?.alias || [],
    redirect: indexR94K3mwxfrMeta?.redirect,
    component: () => import("/opt/build/repo/pages/accedi-registrati/index.vue").then(m => m.default || m)
  },
  {
    name: indexuSjNz7fG7rMeta?.name ?? "accedi-registrati-login",
    path: indexuSjNz7fG7rMeta?.path ?? "/accedi-registrati/login",
    meta: indexuSjNz7fG7rMeta || {},
    alias: indexuSjNz7fG7rMeta?.alias || [],
    redirect: indexuSjNz7fG7rMeta?.redirect,
    component: () => import("/opt/build/repo/pages/accedi-registrati/login/index.vue").then(m => m.default || m)
  },
  {
    name: indexKG4T2TGxZSMeta?.name ?? "area-personale-acquisti-resi",
    path: indexKG4T2TGxZSMeta?.path ?? "/area-personale/acquisti-resi",
    meta: indexKG4T2TGxZSMeta || {},
    alias: indexKG4T2TGxZSMeta?.alias || [],
    redirect: indexKG4T2TGxZSMeta?.redirect,
    component: () => import("/opt/build/repo/pages/area-personale/acquisti-resi/index.vue").then(m => m.default || m)
  },
  {
    name: _91orderID_93tWi3zeYGTzMeta?.name ?? "area-personale-acquisti-resi-ordine-orderID",
    path: _91orderID_93tWi3zeYGTzMeta?.path ?? "/area-personale/acquisti-resi/ordine/:orderID()",
    meta: _91orderID_93tWi3zeYGTzMeta || {},
    alias: _91orderID_93tWi3zeYGTzMeta?.alias || [],
    redirect: _91orderID_93tWi3zeYGTzMeta?.redirect,
    component: () => import("/opt/build/repo/pages/area-personale/acquisti-resi/ordine/[orderID].vue").then(m => m.default || m)
  },
  {
    name: _91orderID_93QNqZvgalG2Meta?.name ?? "area-personale-acquisti-resi-reso-orderID",
    path: _91orderID_93QNqZvgalG2Meta?.path ?? "/area-personale/acquisti-resi/reso/:orderID()",
    meta: _91orderID_93QNqZvgalG2Meta || {},
    alias: _91orderID_93QNqZvgalG2Meta?.alias || [],
    redirect: _91orderID_93QNqZvgalG2Meta?.redirect,
    component: () => import("/opt/build/repo/pages/area-personale/acquisti-resi/reso/[orderID].vue").then(m => m.default || m)
  },
  {
    name: buoniEm5i4ZAQJPMeta?.name ?? "area-personale-buoni",
    path: buoniEm5i4ZAQJPMeta?.path ?? "/area-personale/buoni",
    meta: buoniEm5i4ZAQJPMeta || {},
    alias: buoniEm5i4ZAQJPMeta?.alias || [],
    redirect: buoniEm5i4ZAQJPMeta?.redirect,
    component: () => import("/opt/build/repo/pages/area-personale/buoni.vue").then(m => m.default || m)
  },
  {
    name: datilorYguu5xuMeta?.name ?? "area-personale-dati",
    path: datilorYguu5xuMeta?.path ?? "/area-personale/dati",
    meta: datilorYguu5xuMeta || {},
    alias: datilorYguu5xuMeta?.alias || [],
    redirect: datilorYguu5xuMeta?.redirect,
    component: () => import("/opt/build/repo/pages/area-personale/dati.vue").then(m => m.default || m)
  },
  {
    name: indexT7nUtMi3DNMeta?.name ?? "area-personale",
    path: indexT7nUtMi3DNMeta?.path ?? "/area-personale",
    meta: indexT7nUtMi3DNMeta || {},
    alias: indexT7nUtMi3DNMeta?.alias || [],
    redirect: indexT7nUtMi3DNMeta?.redirect,
    component: () => import("/opt/build/repo/pages/area-personale/index.vue").then(m => m.default || m)
  },
  {
    name: _91editID_93PETb0CYANDMeta?.name ?? "area-personale-indirizzi-editID",
    path: _91editID_93PETb0CYANDMeta?.path ?? "/area-personale/indirizzi/:editID()",
    meta: _91editID_93PETb0CYANDMeta || {},
    alias: _91editID_93PETb0CYANDMeta?.alias || [],
    redirect: _91editID_93PETb0CYANDMeta?.redirect,
    component: () => import("/opt/build/repo/pages/area-personale/indirizzi/[editID].vue").then(m => m.default || m)
  },
  {
    name: indexICbVUygLkUMeta?.name ?? "area-personale-indirizzi",
    path: indexICbVUygLkUMeta?.path ?? "/area-personale/indirizzi",
    meta: indexICbVUygLkUMeta || {},
    alias: indexICbVUygLkUMeta?.alias || [],
    redirect: indexICbVUygLkUMeta?.redirect,
    component: () => import("/opt/build/repo/pages/area-personale/indirizzi/index.vue").then(m => m.default || m)
  },
  {
    name: nuovo5kLED19RAJMeta?.name ?? "area-personale-indirizzi-nuovo",
    path: nuovo5kLED19RAJMeta?.path ?? "/area-personale/indirizzi/nuovo",
    meta: nuovo5kLED19RAJMeta || {},
    alias: nuovo5kLED19RAJMeta?.alias || [],
    redirect: nuovo5kLED19RAJMeta?.redirect,
    component: () => import("/opt/build/repo/pages/area-personale/indirizzi/nuovo.vue").then(m => m.default || m)
  },
  {
    name: indexPlEoG8vsojMeta?.name ?? "area-personale-preferiti",
    path: indexPlEoG8vsojMeta?.path ?? "/area-personale/preferiti",
    meta: indexPlEoG8vsojMeta || {},
    alias: indexPlEoG8vsojMeta?.alias || [],
    redirect: indexPlEoG8vsojMeta?.redirect,
    component: () => import("/opt/build/repo/pages/area-personale/preferiti/index.vue").then(m => m.default || m)
  },
  {
    name: _91page_933MyoTbKLWRMeta?.name ?? "area-personale-preferiti-page-page",
    path: _91page_933MyoTbKLWRMeta?.path ?? "/area-personale/preferiti/page/:page()",
    meta: _91page_933MyoTbKLWRMeta || {},
    alias: _91page_933MyoTbKLWRMeta?.alias || [],
    redirect: _91page_933MyoTbKLWRMeta?.redirect,
    component: () => import("/opt/build/repo/pages/area-personale/preferiti/page/[page].vue").then(m => m.default || m)
  },
  {
    name: prodotti_45disponibiliGIjRsz432DMeta?.name ?? "area-personale-prodotti-disponibili",
    path: prodotti_45disponibiliGIjRsz432DMeta?.path ?? "/area-personale/prodotti-disponibili",
    meta: prodotti_45disponibiliGIjRsz432DMeta || {},
    alias: prodotti_45disponibiliGIjRsz432DMeta?.alias || [],
    redirect: prodotti_45disponibiliGIjRsz432DMeta?.redirect,
    component: () => import("/opt/build/repo/pages/area-personale/prodotti-disponibili.vue").then(m => m.default || m)
  },
  {
    name: wishlisth8PSw8QrQaMeta?.name ?? "area-personale-wishlist",
    path: wishlisth8PSw8QrQaMeta?.path ?? "/area-personale/wishlist",
    meta: wishlisth8PSw8QrQaMeta || {},
    alias: wishlisth8PSw8QrQaMeta?.alias || [],
    redirect: wishlisth8PSw8QrQaMeta?.redirect,
    component: () => import("/opt/build/repo/pages/area-personale/wishlist.vue").then(m => m.default || m)
  },
  {
    name: indexCwEQMvbj7rMeta?.name ?? "b-brand-slug",
    path: indexCwEQMvbj7rMeta?.path ?? "/b-:brand()/:slug()",
    meta: indexCwEQMvbj7rMeta || {},
    alias: indexCwEQMvbj7rMeta?.alias || [],
    redirect: indexCwEQMvbj7rMeta?.redirect,
    component: () => import("/opt/build/repo/pages/b-[brand]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: _91page_93qBOJlOKez5Meta?.name ?? "b-brand-slug-page-page",
    path: _91page_93qBOJlOKez5Meta?.path ?? "/b-:brand()/:slug()/page/:page()",
    meta: _91page_93qBOJlOKez5Meta || {},
    alias: _91page_93qBOJlOKez5Meta?.alias || [],
    redirect: _91page_93qBOJlOKez5Meta?.redirect,
    component: () => import("/opt/build/repo/pages/b-[brand]/[slug]/page/[page].vue").then(m => m.default || m)
  },
  {
    name: indexaUhMcyycnVMeta?.name ?? "b-brand",
    path: indexaUhMcyycnVMeta?.path ?? "/b-:brand()",
    meta: indexaUhMcyycnVMeta || {},
    alias: indexaUhMcyycnVMeta?.alias || [],
    redirect: indexaUhMcyycnVMeta?.redirect,
    component: () => import("/opt/build/repo/pages/b-[brand]/index.vue").then(m => m.default || m)
  },
  {
    name: _91page_93oFvd8nLWGkMeta?.name ?? "b-brand-page-page",
    path: _91page_93oFvd8nLWGkMeta?.path ?? "/b-:brand()/page/:page()",
    meta: _91page_93oFvd8nLWGkMeta || {},
    alias: _91page_93oFvd8nLWGkMeta?.alias || [],
    redirect: _91page_93oFvd8nLWGkMeta?.redirect,
    component: () => import("/opt/build/repo/pages/b-[brand]/page/[page].vue").then(m => m.default || m)
  },
  {
    name: brands0PHvUwVTdiMeta?.name ?? "brands",
    path: brands0PHvUwVTdiMeta?.path ?? "/brands",
    meta: brands0PHvUwVTdiMeta || {},
    alias: brands0PHvUwVTdiMeta?.alias || [],
    redirect: brands0PHvUwVTdiMeta?.redirect,
    component: () => import("/opt/build/repo/pages/brands.vue").then(m => m.default || m)
  },
  {
    name: indexNGTo1wtfXiMeta?.name ?? "c-category",
    path: indexNGTo1wtfXiMeta?.path ?? "/c-:category()",
    meta: indexNGTo1wtfXiMeta || {},
    alias: indexNGTo1wtfXiMeta?.alias || [],
    redirect: indexNGTo1wtfXiMeta?.redirect,
    component: () => import("/opt/build/repo/pages/c-[category]/index.vue").then(m => m.default || m)
  },
  {
    name: _91page_93bXyh0KsniOMeta?.name ?? "c-category-page-page",
    path: _91page_93bXyh0KsniOMeta?.path ?? "/c-:category()/page/:page()",
    meta: _91page_93bXyh0KsniOMeta || {},
    alias: _91page_93bXyh0KsniOMeta?.alias || [],
    redirect: _91page_93bXyh0KsniOMeta?.redirect,
    component: () => import("/opt/build/repo/pages/c-[category]/page/[page].vue").then(m => m.default || m)
  },
  {
    name: carrellofUc11WNL3BMeta?.name ?? "carrello",
    path: carrellofUc11WNL3BMeta?.path ?? "/carrello",
    meta: carrellofUc11WNL3BMeta || {},
    alias: carrellofUc11WNL3BMeta?.alias || [],
    redirect: carrellofUc11WNL3BMeta?.redirect,
    component: () => import("/opt/build/repo/pages/carrello.vue").then(m => m.default || m)
  },
  {
    name: _91id_938KYaDbpSESMeta?.name ?? "checkout-id",
    path: _91id_938KYaDbpSESMeta?.path ?? "/checkout/:id()",
    meta: _91id_938KYaDbpSESMeta || {},
    alias: _91id_938KYaDbpSESMeta?.alias || [],
    redirect: _91id_938KYaDbpSESMeta?.redirect,
    component: () => import("/opt/build/repo/pages/checkout/[id].vue").then(m => m.default || m)
  },
  {
    name: indexz5dTd4TBP7Meta?.name ?? "checkout",
    path: indexz5dTd4TBP7Meta?.path ?? "/checkout",
    meta: indexz5dTd4TBP7Meta || {},
    alias: indexz5dTd4TBP7Meta?.alias || [],
    redirect: indexz5dTd4TBP7Meta?.redirect,
    component: () => import("/opt/build/repo/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: chi_45siamoBz2YjIN22oMeta?.name ?? "chi-siamo",
    path: chi_45siamoBz2YjIN22oMeta?.path ?? "/chi-siamo",
    meta: chi_45siamoBz2YjIN22oMeta || {},
    alias: chi_45siamoBz2YjIN22oMeta?.alias || [],
    redirect: chi_45siamoBz2YjIN22oMeta?.redirect,
    component: () => import("/opt/build/repo/pages/chi-siamo.vue").then(m => m.default || m)
  },
  {
    name: contattiuzIMrJV0pWMeta?.name ?? "contatti",
    path: contattiuzIMrJV0pWMeta?.path ?? "/contatti",
    meta: contattiuzIMrJV0pWMeta || {},
    alias: contattiuzIMrJV0pWMeta?.alias || [],
    redirect: contattiuzIMrJV0pWMeta?.redirect,
    component: () => import("/opt/build/repo/pages/contatti.vue").then(m => m.default || m)
  },
  {
    name: domande_45frequentiq5qF18HL7EMeta?.name ?? "domande-frequenti",
    path: domande_45frequentiq5qF18HL7EMeta?.path ?? "/domande-frequenti",
    meta: domande_45frequentiq5qF18HL7EMeta || {},
    alias: domande_45frequentiq5qF18HL7EMeta?.alias || [],
    redirect: domande_45frequentiq5qF18HL7EMeta?.redirect,
    component: () => import("/opt/build/repo/pages/domande-frequenti.vue").then(m => m.default || m)
  },
  {
    name: _91article_93h6xOIDfs0KMeta?.name ?? "enciclopedia-category-article",
    path: _91article_93h6xOIDfs0KMeta?.path ?? "/enciclopedia/:category()/:article()",
    meta: _91article_93h6xOIDfs0KMeta || {},
    alias: _91article_93h6xOIDfs0KMeta?.alias || [],
    redirect: _91article_93h6xOIDfs0KMeta?.redirect,
    component: () => import("/opt/build/repo/pages/enciclopedia/[category]/[article].vue").then(m => m.default || m)
  },
  {
    name: indexXngl67Q7hqMeta?.name ?? "enciclopedia-category",
    path: indexXngl67Q7hqMeta?.path ?? "/enciclopedia/:category()",
    meta: indexXngl67Q7hqMeta || {},
    alias: indexXngl67Q7hqMeta?.alias || [],
    redirect: indexXngl67Q7hqMeta?.redirect,
    component: () => import("/opt/build/repo/pages/enciclopedia/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: _91page_93J1IzCMxdpEMeta?.name ?? "enciclopedia-category-page-page",
    path: _91page_93J1IzCMxdpEMeta?.path ?? "/enciclopedia/:category()/page/:page()",
    meta: _91page_93J1IzCMxdpEMeta || {},
    alias: _91page_93J1IzCMxdpEMeta?.alias || [],
    redirect: _91page_93J1IzCMxdpEMeta?.redirect,
    component: () => import("/opt/build/repo/pages/enciclopedia/[category]/page/[page].vue").then(m => m.default || m)
  },
  {
    name: indexLQBRbWCa2ZMeta?.name ?? "enciclopedia",
    path: indexLQBRbWCa2ZMeta?.path ?? "/enciclopedia",
    meta: indexLQBRbWCa2ZMeta || {},
    alias: indexLQBRbWCa2ZMeta?.alias || [],
    redirect: indexLQBRbWCa2ZMeta?.redirect,
    component: () => import("/opt/build/repo/pages/enciclopedia/index.vue").then(m => m.default || m)
  },
  {
    name: health_45checkBxn3ItgCqQMeta?.name ?? "health-check",
    path: health_45checkBxn3ItgCqQMeta?.path ?? "/health-check",
    meta: health_45checkBxn3ItgCqQMeta || {},
    alias: health_45checkBxn3ItgCqQMeta?.alias || [],
    redirect: health_45checkBxn3ItgCqQMeta?.redirect,
    component: () => import("/opt/build/repo/pages/health-check.vue").then(m => m.default || m)
  },
  {
    name: indexlaFeZuYZkUMeta?.name ?? "index",
    path: indexlaFeZuYZkUMeta?.path ?? "/",
    meta: indexlaFeZuYZkUMeta || {},
    alias: indexlaFeZuYZkUMeta?.alias || [],
    redirect: indexlaFeZuYZkUMeta?.redirect,
    component: () => import("/opt/build/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: loginBXD9sq3T3sMeta?.name ?? "login",
    path: loginBXD9sq3T3sMeta?.path ?? "/login",
    meta: loginBXD9sq3T3sMeta || {},
    alias: loginBXD9sq3T3sMeta?.alias || [],
    redirect: loginBXD9sq3T3sMeta?.redirect,
    component: () => import("/opt/build/repo/pages/login.vue").then(m => m.default || m)
  },
  {
    name: _91refOrder_93bIPfyRftwdMeta?.name ?? "ordine-traccia-email-refOrder",
    path: _91refOrder_93bIPfyRftwdMeta?.path ?? "/ordine/traccia/:email()/:refOrder()",
    meta: _91refOrder_93bIPfyRftwdMeta || {},
    alias: _91refOrder_93bIPfyRftwdMeta?.alias || [],
    redirect: _91refOrder_93bIPfyRftwdMeta?.redirect,
    component: () => import("/opt/build/repo/pages/ordine/traccia/[email]/[refOrder].vue").then(m => m.default || m)
  },
  {
    name: trovaWxQk7ASVerMeta?.name ?? "ordine-trova",
    path: trovaWxQk7ASVerMeta?.path ?? "/ordine/trova",
    meta: trovaWxQk7ASVerMeta || {},
    alias: trovaWxQk7ASVerMeta?.alias || [],
    redirect: trovaWxQk7ASVerMeta?.redirect,
    component: () => import("/opt/build/repo/pages/ordine/trova.vue").then(m => m.default || m)
  },
  {
    name: p_45_91product_93_46constU2OD4q9Sv4Meta?.name ?? "p-product.const",
    path: p_45_91product_93_46constU2OD4q9Sv4Meta?.path ?? "/p-:product().const",
    meta: p_45_91product_93_46constU2OD4q9Sv4Meta || {},
    alias: p_45_91product_93_46constU2OD4q9Sv4Meta?.alias || [],
    redirect: p_45_91product_93_46constU2OD4q9Sv4Meta?.redirect,
    component: () => import("/opt/build/repo/pages/p-[product].const.ts").then(m => m.default || m)
  },
  {
    name: p_45_91product_93E7gXPSwJQgMeta?.name ?? "p-product",
    path: p_45_91product_93E7gXPSwJQgMeta?.path ?? "/p-:product()",
    meta: p_45_91product_93E7gXPSwJQgMeta || {},
    alias: p_45_91product_93E7gXPSwJQgMeta?.alias || [],
    redirect: p_45_91product_93E7gXPSwJQgMeta?.redirect,
    component: () => import("/opt/build/repo/pages/p-[product].vue").then(m => m.default || m)
  },
  {
    name: indexQiExrVPiPcMeta?.name ?? "password-dimenticata",
    path: indexQiExrVPiPcMeta?.path ?? "/password-dimenticata",
    meta: indexQiExrVPiPcMeta || {},
    alias: indexQiExrVPiPcMeta?.alias || [],
    redirect: indexQiExrVPiPcMeta?.redirect,
    component: () => import("/opt/build/repo/pages/password-dimenticata/index.vue").then(m => m.default || m)
  },
  {
    name: modifica_45passwordGoJJyz8DQYMeta?.name ?? "password-dimenticata-modifica-password",
    path: modifica_45passwordGoJJyz8DQYMeta?.path ?? "/password-dimenticata/modifica-password",
    meta: modifica_45passwordGoJJyz8DQYMeta || {},
    alias: modifica_45passwordGoJJyz8DQYMeta?.alias || [],
    redirect: modifica_45passwordGoJJyz8DQYMeta?.redirect,
    component: () => import("/opt/build/repo/pages/password-dimenticata/modifica-password.vue").then(m => m.default || m)
  },
  {
    name: indexjrKiqFWcmmMeta?.name ?? "piu-acquistati",
    path: indexjrKiqFWcmmMeta?.path ?? "/piu-acquistati",
    meta: indexjrKiqFWcmmMeta || {},
    alias: indexjrKiqFWcmmMeta?.alias || [],
    redirect: indexjrKiqFWcmmMeta?.redirect,
    component: () => import("/opt/build/repo/pages/piu-acquistati/index.vue").then(m => m.default || m)
  },
  {
    name: _91page_93lHTT0MM2xdMeta?.name ?? "piu-acquistati-page-page",
    path: _91page_93lHTT0MM2xdMeta?.path ?? "/piu-acquistati/page/:page()",
    meta: _91page_93lHTT0MM2xdMeta || {},
    alias: _91page_93lHTT0MM2xdMeta?.alias || [],
    redirect: _91page_93lHTT0MM2xdMeta?.redirect,
    component: () => import("/opt/build/repo/pages/piu-acquistati/page/[page].vue").then(m => m.default || m)
  },
  {
    name: profilo_45cancellato2DiM9hmnlKMeta?.name ?? "profilo-cancellato",
    path: profilo_45cancellato2DiM9hmnlKMeta?.path ?? "/profilo-cancellato",
    meta: profilo_45cancellato2DiM9hmnlKMeta || {},
    alias: profilo_45cancellato2DiM9hmnlKMeta?.alias || [],
    redirect: profilo_45cancellato2DiM9hmnlKMeta?.redirect,
    component: () => import("/opt/build/repo/pages/profilo-cancellato.vue").then(m => m.default || m)
  },
  {
    name: index6pxx5XbGeeMeta?.name ?? "promozioni-brand-slug",
    path: index6pxx5XbGeeMeta?.path ?? "/promozioni/brand/:slug()",
    meta: index6pxx5XbGeeMeta || {},
    alias: index6pxx5XbGeeMeta?.alias || [],
    redirect: index6pxx5XbGeeMeta?.redirect,
    component: () => import("/opt/build/repo/pages/promozioni/brand/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: _91page_93hXuvABQhtlMeta?.name ?? "promozioni-brand-slug-page-page",
    path: _91page_93hXuvABQhtlMeta?.path ?? "/promozioni/brand/:slug()/page/:page()",
    meta: _91page_93hXuvABQhtlMeta || {},
    alias: _91page_93hXuvABQhtlMeta?.alias || [],
    redirect: _91page_93hXuvABQhtlMeta?.redirect,
    component: () => import("/opt/build/repo/pages/promozioni/brand/[slug]/page/[page].vue").then(m => m.default || m)
  },
  {
    name: indexHFWpM3cPk4Meta?.name ?? "promozioni",
    path: indexHFWpM3cPk4Meta?.path ?? "/promozioni",
    meta: indexHFWpM3cPk4Meta || {},
    alias: indexHFWpM3cPk4Meta?.alias || [],
    redirect: indexHFWpM3cPk4Meta?.redirect,
    component: () => import("/opt/build/repo/pages/promozioni/index.vue").then(m => m.default || m)
  },
  {
    name: indexY3fHhsOGd5Meta?.name ?? "promozioni-interne-slug",
    path: indexY3fHhsOGd5Meta?.path ?? "/promozioni/interne/:slug()",
    meta: indexY3fHhsOGd5Meta || {},
    alias: indexY3fHhsOGd5Meta?.alias || [],
    redirect: indexY3fHhsOGd5Meta?.redirect,
    component: () => import("/opt/build/repo/pages/promozioni/interne/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: _91page_93w0wI6gtigBMeta?.name ?? "promozioni-interne-slug-page-page",
    path: _91page_93w0wI6gtigBMeta?.path ?? "/promozioni/interne/:slug()/page/:page()",
    meta: _91page_93w0wI6gtigBMeta || {},
    alias: _91page_93w0wI6gtigBMeta?.alias || [],
    redirect: _91page_93w0wI6gtigBMeta?.redirect,
    component: () => import("/opt/build/repo/pages/promozioni/interne/[slug]/page/[page].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93W18cDwZAlBMeta?.name ?? "promozioni-special-slug",
    path: _91slug_93W18cDwZAlBMeta?.path ?? "/promozioni/special/:slug()",
    meta: _91slug_93W18cDwZAlBMeta || {},
    alias: _91slug_93W18cDwZAlBMeta?.alias || [],
    redirect: _91slug_93W18cDwZAlBMeta?.redirect,
    component: () => import("/opt/build/repo/pages/promozioni/special/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexaC1HOsJopwMeta?.name ?? "ricerca",
    path: indexaC1HOsJopwMeta?.path ?? "/ricerca",
    meta: indexaC1HOsJopwMeta || {},
    alias: indexaC1HOsJopwMeta?.alias || [],
    redirect: indexaC1HOsJopwMeta?.redirect,
    component: () => import("/opt/build/repo/pages/ricerca/index.vue").then(m => m.default || m)
  },
  {
    name: _91page_93q6Pm6jsPkeMeta?.name ?? "ricerca-page-page",
    path: _91page_93q6Pm6jsPkeMeta?.path ?? "/ricerca/page/:page()",
    meta: _91page_93q6Pm6jsPkeMeta || {},
    alias: _91page_93q6Pm6jsPkeMeta?.alias || [],
    redirect: _91page_93q6Pm6jsPkeMeta?.redirect,
    component: () => import("/opt/build/repo/pages/ricerca/page/[page].vue").then(m => m.default || m)
  },
  {
    name: _91file_93MAh3jESMNoMeta?.name ?? "ricevuta-file",
    path: _91file_93MAh3jESMNoMeta?.path ?? "/ricevuta/:file()",
    meta: _91file_93MAh3jESMNoMeta || {},
    alias: _91file_93MAh3jESMNoMeta?.alias || [],
    redirect: _91file_93MAh3jESMNoMeta?.redirect,
    component: () => import("/opt/build/repo/pages/ricevuta/[file].vue").then(m => m.default || m)
  },
  {
    name: richiedi_45prodotto7uFecg0PPwMeta?.name ?? "richiedi-prodotto",
    path: richiedi_45prodotto7uFecg0PPwMeta?.path ?? "/richiedi-prodotto",
    meta: richiedi_45prodotto7uFecg0PPwMeta || {},
    alias: richiedi_45prodotto7uFecg0PPwMeta?.alias || [],
    redirect: richiedi_45prodotto7uFecg0PPwMeta?.redirect,
    component: () => import("/opt/build/repo/pages/richiedi-prodotto.vue").then(m => m.default || m)
  },
  {
    name: scontiqXktzTDAFOMeta?.name ?? "sconti",
    path: scontiqXktzTDAFOMeta?.path ?? "/sconti",
    meta: scontiqXktzTDAFOMeta || {},
    alias: scontiqXktzTDAFOMeta?.alias || [],
    redirect: scontiqXktzTDAFOMeta?.redirect,
    component: () => import("/opt/build/repo/pages/sconti.vue").then(m => m.default || m)
  },
  {
    name: sitemapx1fjhjGxmTMeta?.name ?? "sitemap",
    path: sitemapx1fjhjGxmTMeta?.path ?? "/sitemap",
    meta: sitemapx1fjhjGxmTMeta || {},
    alias: sitemapx1fjhjGxmTMeta?.alias || [],
    redirect: sitemapx1fjhjGxmTMeta?.redirect,
    component: () => import("/opt/build/repo/pages/sitemap.vue").then(m => m.default || m)
  },
  {
    name: social_45loginPWYrRfhs2eMeta?.name ?? "social-login",
    path: social_45loginPWYrRfhs2eMeta?.path ?? "/social-login",
    meta: social_45loginPWYrRfhs2eMeta || {},
    alias: social_45loginPWYrRfhs2eMeta?.alias || [],
    redirect: social_45loginPWYrRfhs2eMeta?.redirect,
    component: () => import("/opt/build/repo/pages/social-login.vue").then(m => m.default || m)
  },
  {
    name: indexHn1P26pVy5Meta?.name ?? "top-magazine-category-slug",
    path: indexHn1P26pVy5Meta?.path ?? "/top-magazine/:category()/:slug()",
    meta: indexHn1P26pVy5Meta || {},
    alias: indexHn1P26pVy5Meta?.alias || [],
    redirect: indexHn1P26pVy5Meta?.redirect,
    component: () => import("/opt/build/repo/pages/top-magazine/[category]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexa2OoLPajMOMeta?.name ?? "top-magazine-category",
    path: indexa2OoLPajMOMeta?.path ?? "/top-magazine/:category()",
    meta: indexa2OoLPajMOMeta || {},
    alias: indexa2OoLPajMOMeta?.alias || [],
    redirect: indexa2OoLPajMOMeta?.redirect,
    component: () => import("/opt/build/repo/pages/top-magazine/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: _91page_93hefhzrrG0vMeta?.name ?? "top-magazine-category-page-page",
    path: _91page_93hefhzrrG0vMeta?.path ?? "/top-magazine/:category()/page/:page()",
    meta: _91page_93hefhzrrG0vMeta || {},
    alias: _91page_93hefhzrrG0vMeta?.alias || [],
    redirect: _91page_93hefhzrrG0vMeta?.redirect,
    component: () => import("/opt/build/repo/pages/top-magazine/[category]/page/[page].vue").then(m => m.default || m)
  },
  {
    name: indexgz58Wm79gIMeta?.name ?? "top-magazine",
    path: indexgz58Wm79gIMeta?.path ?? "/top-magazine",
    meta: indexgz58Wm79gIMeta || {},
    alias: indexgz58Wm79gIMeta?.alias || [],
    redirect: indexgz58Wm79gIMeta?.redirect,
    component: () => import("/opt/build/repo/pages/top-magazine/index.vue").then(m => m.default || m)
  },
  {
    name: indexGAXrrwPzOqMeta?.name ?? "top-magazine-tutti-i-post",
    path: indexGAXrrwPzOqMeta?.path ?? "/top-magazine/tutti-i-post",
    meta: indexGAXrrwPzOqMeta || {},
    alias: indexGAXrrwPzOqMeta?.alias || [],
    redirect: indexGAXrrwPzOqMeta?.redirect,
    component: () => import("/opt/build/repo/pages/top-magazine/tutti-i-post/index.vue").then(m => m.default || m)
  },
  {
    name: _91page_93G46ZqFrjStMeta?.name ?? "top-magazine-tutti-i-post-page-page",
    path: _91page_93G46ZqFrjStMeta?.path ?? "/top-magazine/tutti-i-post/page/:page()",
    meta: _91page_93G46ZqFrjStMeta || {},
    alias: _91page_93G46ZqFrjStMeta?.alias || [],
    redirect: _91page_93G46ZqFrjStMeta?.redirect,
    component: () => import("/opt/build/repo/pages/top-magazine/tutti-i-post/page/[page].vue").then(m => m.default || m)
  },
  {
    name: component_45stubVlEI75ehPBMeta?.name ?? undefined,
    path: component_45stubVlEI75ehPBMeta?.path ?? "/sitemap.xml",
    meta: component_45stubVlEI75ehPBMeta || {},
    alias: component_45stubVlEI75ehPBMeta?.alias || [],
    redirect: component_45stubVlEI75ehPBMeta?.redirect,
    component: () => import("/opt/build/repo/node_modules/nuxt/dist/pages/runtime/component-stub").then(m => m.default || m)
  }
]