<script setup lang="ts">
import { FormKitLazyProvider } from '@formkit/vue'
import { SearchbarProps } from "./Searchbar.props"
import { customPaths } from "~/utils/constants"
import { addSearchTermIntoCookie } from "~/utils/cookies/searchCookies"
import { searchMethod, setSearchMethod } from "~/utils/analytics/search"

const props = defineProps<SearchbarProps>()

const { triggerFocus, modelValue, forceQuery } = toRefs(props)
const emit =
  defineEmits<{
    (e: "onFocusIn"): void
    (e: "onFocusOut"): void
    (e: "onSearch"): void
    (e: "update:modelValue", value: string | undefined): void
  }>()

const inputSearch = ref()
const searchbar = ref()
const lastQueries = useStatefulCookie<string[]>("lastQueries", {
  maxAge: 2592000,
  default: () => []
})

const sharedSearchStatus = useState("SEARCH_STATE", () => modelValue.value)
const algoliaSearchbarQueryState = useState("searchbar-query-state")

const isFocusedPreMount = ref(false)

onMounted(() => {
  if (
    isFocusedPreMount.value ||
    (document.activeElement?.name &&
      props.name === document.activeElement?.name)
  ) {
    algoliaSearchbarQueryState.value = searchValue.value
    emit("onFocusIn")
  }
  if (props.forceQuery) {
    searchValue.value = props.forceQuery
  }
  isFocusedPreMount.value = false
})

watch(modelValue, (value) => {
  if (props.doNotOpenPopup) return
  sharedSearchStatus.value = value
})

// autofocusing when triggering focus
watch(
  triggerFocus,
  (val) => {
    nextTick(() => {
      if (val) {
        const searchbarInput = searchbar.value?.querySelector("input")
        if (searchbarInput) {
          searchbarInput.focus()
        }
      }
    })
  },
  { immediate: true }
)

const isValid = computed(() => {
  if (inputSearch.value) {
    const node = inputSearch.value.node
    if (node.context.state.dirty && node.context.state.rules)
      return node.context.state.complete
  }
  return true
})

const isFocused = ref(false)

const handleFocusIn = () => {
  isFocused.value = true
  sharedSearchStatus.value = props.modelValue ?? ""
  emit("onFocusIn")
}

const handleFocusOut = () => {
  isFocused.value = false
  emit("onFocusOut")
}

const searchValue = defineModel<string | undefined>()

// handle everything about placeholder typing like
const currentPlaceholder = ref("")
const WRITING_TIME = 120
const CLEARING_TIME = 40
const interval = ref(WRITING_TIME)
let isWriting = ref(true)

let charToShow = ref(0)
const { pause, resume } = useIntervalFn(() => {
  const placeholder = props.placeholder
  if (placeholder) {
    currentPlaceholder.value = placeholder.slice(0, charToShow.value)

    if (charToShow.value === placeholder.length) {
      isWriting.value = false
      interval.value = CLEARING_TIME
      // pause if placeholder is all written
      stopWriting(500)
    } else if (charToShow.value === 0) {
      isWriting.value = true
      interval.value = WRITING_TIME
    }

    // pause if last char is a comma
    if (isWriting.value && currentPlaceholder.value.slice(-1) === ",") {
      stopWriting(500)
    }
    isWriting.value ? charToShow.value++ : charToShow.value--
  }
}, interval)

const stopWriting = (interval: MaybeComputedRef<number>) => {
  pause()
  useTimeoutFn(() => {
    resume()
  }, interval)
}

const restartWriting = () => {
  pause()
  charToShow.value = 0
  isWriting.value = true
  currentPlaceholder.value = ""
  resume()
}

watch(searchValue, () => {
  if (!!searchValue.value) restartWriting()
})

const cleanValue = () => {
  searchValue.value = ""
  algoliaSearchbarQueryState.value = ""
}

watch(forceQuery, (newForceQuery) => {
  searchValue.value = newForceQuery
})

const { switchState } = useUIState("focus-search")

// This may be used to also disable the search button to make it clear that the query isn't valid
function isSearchInvalid(input: string | undefined): boolean {
  if (!input || input.trim().length === 0) return true

  const specialCharRegex = /^[^a-zA-Z0-9]+$/
  if (specialCharRegex.test(input)) return true

  return false
}

const handleSearchPopup = () => {
  emit("onSearch")

  if (props.doNotOpenPopup) return

  lastQueries.value = addSearchTermIntoCookie(
    lastQueries.value,
    searchValue.value || ""
  )

  if (isSearchInvalid(searchValue.value)) {
    switchState(true)
  } else {
    navigateTo({
      path: customPaths.search,
      ...(!!lastQueries?.value?.length && {
        query: { query: lastQueries.value[0] }
      })
    })
  }
}

const placeholderText = computed(() =>
  props.disabled || !props.isPlaceholderAnimated
    ? props.placeholder
    : currentPlaceholder
)

const searchButton: Ref<HTMLElement | null> = ref(null)

const triggerClickButton = () => {
  searchButton.value!.click()
}
</script>

<template>
  <div class="search-searchbar w-full" ref="searchbar">
    
<FormKitLazyProvider config-file="true">
<FormKit
      ref="inputSearch"
      :id="name"
      :name="name"
      type="text"
      autocomplete="off"
      v-model="searchValue"
      :placeholder="placeholderText"
      :disabled="disabled"
      :classes="{
        outer: 'relative',
        wrapper: 'relative bg-inherit',
        label: 'sr-only',
        input: {
          'pl-4 text-ellipsis w-full input-text__textfield bg-white border disabled:border-tiffany-main focus:border-orange-main rounded-[100px] pr-12 placeholder:!text-black-80 placeholder:select-none text-black-main': true,
          'error-border__textfield': !isValid,
          'text-black-20 placeholder:!text-black-20': disabled,
          'border-orange-main border-2 py-[11px]': isOpen,
          'border-tiffany-main py-[12px]': !isOpen,
          'pr-[75px]': searchValue && !forceSearchButton
        }
      }"
      @focusin="handleFocusIn"
      @focusout="handleFocusOut"
      @keyup.enter="triggerClickButton"
    >
      <template #suffixIcon>
        <button
          v-if="searchValue && !forceSearchButton"
          @click="cleanValue"
          :disabled="disabled"
          :class="`absolute top-1/2 -translate-y-1/2 ${
            showTextIcon ? 'right-28' : 'right-12'
          }`"
        >
          <UtilsIcon
            name="CloseCircleFull.svg"
            :preload="preloadIcons ? true : undefined"
            :fetch-priority="preloadIcons ? 'high' : undefined"
            color="black-40"
            class="h-6 w-6"
          />
        </button>
        <button
          :disabled="disabled"
          @click="handleSearchPopup"
          class="
            absolute
            right-[3px]
            top-1/2
            flex
            -translate-y-1/2
            items-center
            justify-center
            rounded-full
            bg-yellow-main
          "
          ref="searchButton"
          :class="
            showTextIcon
              ? 'mr-[3px] h-[38px] w-[38px] gap-1 md:h-max md:w-max md:py-[9px] md:pl-3 md:pr-2'
              : 'mr-[2px] p-[7px]'
          "
        >
          <p v-if="showTextIcon" class="pig-medium hidden md:block">
            {{ $t("searchbar.search") }}
          </p>
          <UtilsIcon
            name="Search.svg"
            class="h-6 w-6 flex-shrink-0"
            :preload="preloadIcons ? true : undefined"
            :fetch-priority="preloadIcons ? 'high' : undefined"
            :class="{ 'md:h-5 md:w-5': showTextIcon }"
            v-if="!isFocusedPreMount"
          />
          <UtilsLoader v-if="isFocusedPreMount" dimensions="small-medium" />
        </button>
      </template>
    </FormKit>
</FormKitLazyProvider>

  </div>
</template>
