<script setup lang="ts">
import { ProductsProps } from "./Products.props"
import { formatMoney } from "~/utils/formatters"

defineProps<ProductsProps>()

const emit =
  defineEmits<{
    (e: "onClose"): void
    (e: "notification", productName: string): void
  }>()

const { resetNotifications } = useNotification()

onMounted(() => resetNotifications())
</script>

<template>
  <div class="modal-product-wrapper">
    <DialogsAndModalsStandard
      :title="title"
      @on-close="emit('onClose')"
      fullscreen
    >
      <template #content>
        <div
          class="
            custom-height
            relative
            flex flex-col
            gap-4
            px-4
            pb-16
            pt-4
            md:gap-6
            md:px-7
            md:pb-8
            md:pt-6
          "
          :class="{
            'grid-container md:col-span-6': products?.length === 1,
            'grid-container md:col-span-8': products?.length === 2,
            'grid-container md:col-span-10': products?.length === 3
          }"
        >
          <GAListModalCartProducts :name="`${title}`">
            <div class="flex flex-col flex-wrap items-center">
              <GAItem id="need-more-products" :item="products">
                <GAViewItemList method="carrello"> </GAViewItemList>
              </GAItem>

              <div class="flex flex-col gap-4 md:gap-7">
                <p v-if="text" class="dolphin-bold text-left">
                  {{ text }}
                </p>

                <div v-if="products" class="flex">
                  <div class="hidden justify-evenly gap-6 md:flex">
                    <template
                      v-for="(product, j) in products"
                      :key="`${product.title}-vertical`"
                    >
                      <ProductTileOverallVertical
                        v-bind="product"
                        @notification="emit('notification', product.title)"
                        class="!h-full flex-1 md:!min-w-[220px]"
                        :class="{
                          'flex-1': products?.length > 1
                        }"
                        :showSaveForLater="false"
                        :is-in-precheckout="isInPrecheckout"
                        :itemList="`Modal Cart: ${title}`"
                      />
                    </template>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="products?.length"
              class="flex flex-wrap justify-between gap-4 md:hidden"
            >
              <template
                v-for="product in products"
                :key="`${product.title}-search`"
              >
                <ProductTileOverallSearch
                  theme="outlined-green"
                  v-bind="product"
                  class="max-w-[450px]"
                  @notification="emit('notification', product.title)"
                />
              </template>
            </div>
          </GAListModalCartProducts>
        </div>
      </template>

      <template v-if="cta" #cta>
        <div
          class="
            flex
            w-full
            flex-col
            justify-end
            bg-white
            p-4
            md:relative
            md:flex-row
            md:items-center
            md:gap-6
            md:px-7
            md:pt-6
          "
        >
          <div class="mb-2 flex items-center justify-between md:mb-0 md:gap-2">
            <span class="beaver-bold">{{ cta?.infoText }}</span>
            <strong class="hyppo">{{ formatMoney(cta?.price ?? 0) }}</strong>
          </div>
          <UtilsButton class="w-full md:w-max" v-bind="cta.cta" />
        </div>
      </template>
    </DialogsAndModalsStandard>
  </div>
</template>
